import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import FeatureFunctions from '../../components/AboutUs/FeatureFunctions'
import featureImg from '/static/img/security-features/security-center-lock-screen.webp'
import {graphql} from 'gatsby'
const img = '/img/security-features/security-center-lock-screen.webp'

const dataLockScreen = {
  title: "Secure Lock Screen",
  subtitle: <div>
 <p>BlueMail offers a secure Lock Screen feature, that utilizing passcode or fingerprint security, ensuring that your emails will remain safe even if you were to lose your device or hand it off to another person.</p> 
 <p> Have it display immediately, or trigger after a few minutes, always knowing that BlueMail’s Lock Screen security will activate and protect your valuable information.</p>
 <p><a href="https://bluemail.me/help/enable-lock-screen/">Learn how</a> to enable it on your device.</p>
</div>,
  img: featureImg,
  class: ''
}

const LockScreen = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='Secure Lock Screen | BlueMail App' 
                postDescription='Utilizing passcode or fingerprint security, BlueMail offers a secure Lock Screen feature, that ensures your emails will remain safe even if you were to lose your device or hand it off to another person.'
                postImage="/img/OG/og_image-security-center-lock-screen.png" 
                postURL='security/lock-screen'
                postSEO
            />
            <Navbar />
            <FeatureFunctions data={dataLockScreen} />
            <Footer />
        </Layout>
    )
}

export default LockScreen

export const query = graphql`
query LockScreenQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
